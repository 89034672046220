.container {
    display: flex;
    flex-direction: column
}

.remove-button {
    cursor: pointer;
    text-align: right
}

.remove-button:hover {
    color: red;
}

.libs-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid lightgray;
    padding: 5px
}
.libs-header {
    flex: 1;
    display: flex;
    flex-direction: row;
    padding-left: 5px
}
.libs-header-cell{
    margin-top: 7px;
    margin-bottom: 7px;
}

.lib-input{
    margin: 5px 5px 5px 0
}

.flex-1 {
    flex: 1
}

.flex-2 {
    flex: 2
}

.flex-5 {
    flex: 5
}

.flex-6 {
    flex: 6
}

.button {
    background-color: #285154;
    color: #ffffff;
    border: solid 0;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    margin: 5px;
}

.loader {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: white;
    opacity: 0.7;
}

.loader-icon {
    animation-name: spin;
    animation-duration: 800ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 30px;
    height: 30px;
    border: solid 5px lightgray;
    border-top-color: green;
    border-radius: 50%;
}
.error-message{
    color: rgb(207, 16, 16);
    font-size: small;
    white-space: pre-wrap;
    margin: 5px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}